<template>
    <div class="package-order-details" style="width: 100%">
        <div style="width: 100%">
            <el-form size="small" :inline="true" :model="packageOrderDetailsInfo" class="demo-form-inline"
                label-width="100px" label-position="right">
                <div class="title-wrap clearfix">
                    <el-form-item class="back-btn">
                        <el-button @click="goBack">返回</el-button>
                    </el-form-item>
                    <span class="title-text">{{ packageOrderDetailsData.pName }}</span>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="space-around" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8">
                            <el-form-item label="交易状态：">{{ tradingStatusName() }}</el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="支付限时："></el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-title">订单信息</div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="10" :offset="2">
                            <el-form-item label="订单编号：">{{ packageOrderDetailsInfo.orderNumber }}</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="10" :offset="2">
                            <el-form-item label="商品名称：">{{ packageOrderDetailsInfo.pName }}</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="10" :offset="2">
                            <el-form-item label="服务类型：">{{ packageOrderDetailsInfo.serviceName }}</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="space-around" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8">
                            <el-form-item label="账号数：">{{ packageOrderDetailsInfo.pAccount }} 个</el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="服务时间：">{{ packageOrderDetailsInfo.pDuration }} 天</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="space-around" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8">
                            <el-form-item label="云空间：">{{ packageOrderDetailsInfo.pSpace }} G</el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="构件容量数：">{{ packageOrderDetailsInfo.pComponent }} 个</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8" :offset="2">
                            <el-form-item label="支付方式：">{{ payMethodName() }}</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="space-around" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8">
                            <el-form-item label="下单时间：">{{ packageOrderDetailsInfo.starTime }}</el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="支付时间：">{{ packageOrderDetailsInfo.bPayDatetime }}</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8" :offset="2">
                            <el-form-item label="支付人：">{{ packageOrderDetailsInfo.username }}</el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="space-around" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8">
                            <el-form-item label="支付账户：">{{ packageOrderDetailsInfo.bRemark.bankAccount }}</el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="所属银行：">{{ packageOrderDetailsInfo.bRemark.accountsBank }}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8" :offset="2">
                            <el-form-item label="下单设备："></el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8" :offset="2">
                            <el-form-item label="支付设备："></el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8" :offset="2">
                            <el-form-item label="下单IP："></el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-wrap">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8" :offset="2">
                            <el-form-item label="支付IP："></el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="line-title" v-if="actionType != 1">操作</div>
                <div class="line-wrap" v-if="actionType != 1">
                    <el-row :gutter="20" justify="start" style="margin-left: -50px; margin-right: -200px;">
                        <el-col :span="8" :offset="2">
                            <el-form-item style="color: rgb(23, 144, 255); cursor: pointer;"
                                :label="actionType === -1 ? '取消：' : '补单：'" @click="clickActionBtn">点击操作</el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { statusArray, payMethodLsit } from '@/common/js/order-manage-data/status-list.js';
import jsonTool from '@/utils/jsonTool.js';
import { getPackageOrderDetailsInfo, packageOrderChangeStatus } from '@/common/api/order-manage/packageOrder.js';
export default {
    name: 'packageOrderDetails',
    props: {
        packageOrderDetailsData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            packageOrderDetailsInfo: {
                // 订单编号
                orderNumber: '',
                // 商品名称
                pName: '',
                // 服务类型
                serviceName: '',
                // 账号数
                pAccount: 0,
                // 服务时间
                pDuration: 0,
                // 云空间
                pSpace: 0,
                // 构件容量数
                pComponent: 0,
                // 下单时间
                starTime: '',
                // 支付时间
                bPayDatetime: '',
                // 支付人
                username: '',
                bRemark: {}
            },
            // 操作类型
            actionType: ''
        }
    },
    mounted() {
        this.querypackageOrderDetailsInfo();
    },
    methods: {
        goBack() {
            this.$emit('cancel');
        },
        // 交易状态名字
        tradingStatusName() {
            return statusArray.find(val => val.id === this.packageOrderDetailsInfo.bStatus)?.name;
        },
        // 支付方式名称
        payMethodName() {
            return payMethodLsit.find(val => val.id === this.packageOrderDetailsInfo.bType)?.name;
        },
        // 查询订单详情数据
        querypackageOrderDetailsInfo() {
            let params = {
                b_id: this.packageOrderDetailsData.bId
            }
            getPackageOrderDetailsInfo(params).then(res => {
                if (res.code == 200) {
                    this.packageOrderDetailsInfo = jsonTool(res.result);
                    this.actionType = this.packageOrderDetailsInfo.bStatus
                }
            }).catch(err => {
                // console.log(err);
            })
        },
        clickActionBtn() {
            let actionName = this.actionType === -1 ? "取消" : "补单";
            let params = {
                b_id: this.packageOrderDetailsInfo.bId,
                get_b_status: this.actionType
            }
            this.$confirm(`是否进行${actionName}操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                packageOrderChangeStatus(params).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                            center: true
                        })
                        this.$emit('cancel');
                    }
                }).catch(err => {
                    // console.log(err);
                })
            }).catch(() => {
                this.$message({
                    type: 'success',
                    message: '已取消操作!',
                    center: true
                })
            });
        },
    }
};
</script>

<style scoped>
/* $borderBottom: 1px solid var(--el-border-color-base); */
.clearfix::after {
    clear: both;
    display: block;
    content: '';
    height: 0;
    visibility: collapse;
}

.title-wrap {
    height: 56px;
    text-align: center;
    border-bottom: 1px solid var(--el-border-color-base);
}

.back-btn {
    float: left;
}

.title-text {
    font-weight: 500;
    font-size: 22px;
    color: #4D4D4D;
}

:deep(.el-form-item__label) {
    color: #9C9FE8;
}

.line-wrap,
.line-title {
    width: 80%;
    height: 56px;
    margin: auto;
    border-bottom: 1px solid var(--el-border-color-base);
    line-height: 56px;
}

.line-title {
    text-indent: 8%;
    line-height: 78px !important;
}
</style>