<template>
    <div class="history">
        <el-row style="padding: 25px">
            <packageOrder v-if="showPage == 'order'" @packageOrderInfo="packageOrderInfo"></packageOrder>
            <packageOrderDetails
                v-if="showPage == 'details'"
                @cancel="cancel"
                :packageOrderDetailsData="packageOrderDetailsData"
            ></packageOrderDetails>
        </el-row>
    </div>
</template>
<script>
import packageOrder from './packageOrder.vue';
import packageOrderDetails from './packageOrderDetails.vue';
export default {
    components: {
        packageOrder,
        packageOrderDetails
    },
    data() {
        return {
            showPage: 'order',
            // 品牌管理column数据
            packageOrderDetailsData: {}
        };
    },
    // beforeRouteEnter(to, from, next) {
    //     if (to.query.bId && to.query.pName) {
    //         next(vm => {
    //             // 通过 `vm` 访问组件实例
    //             vm.packageOrderInfo(to.query);
    //         });
    //     } else {
    //         next();
    //     }
    // },
    methods: {
        packageOrderInfo(row) {
            this.showPage = 'details';
            let { pName, bId } = row;
            this.packageOrderDetailsData = {
                pName,
                bId
            };
        },
        cancel() {
            // this.$router.push({ path: '/packageOrder' });
            this.showPage = 'order';
        }
    }
};
</script>
<style scoped>
.demo-input-label {
    display: inline-block;
    width: 130px;
}
</style>
