<template>
    <div>
        <el-row style="padding: 25px">
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item label="名称:">
                        <el-input style="width: 200px" v-model="searchForm.searchVal" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select clearable style="width: 200px" v-model="searchForm.status">
                            <el-option v-for="item in statusArray" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 100%">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="packageOrderDataList" border
                    align="center" style="width: 100%">
                    <el-table-column width="90px" label="序号" type="index" :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column prop="orderNumber" label="订单编号" align="center"></el-table-column>
                    <el-table-column prop="pName" label="名称" align="center"></el-table-column>
                    <el-table-column prop="startTime" label="购买时间" align="center"></el-table-column>
                    <el-table-column prop="username" label="购买者" align="center"></el-table-column>
                    <el-table-column prop="transferRemark" label="转账备注" align="center"></el-table-column>
                    <el-table-column prop="status" label="状态" align="center">
                        <template v-slot="scope">
                            <div>{{ statusName(scope.row) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="价格" align="center">
                        <template v-slot="scope">
                            <div>￥{{ scope.row.comboMoney }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <!-- <template v-slot="scope"> -->
                            <!-- <el-button @click="handleToViewBtn(scope.row)" type="text" size="small">查看</el-button>
                            <el-button
                                :disabled="!permissionsAction.enable"
                                @click="handleChangeStatus(scope.row)"
                                type="text"
                                size="small"
                                v-if="scope.row.bStatus != 1"
                                >{{ scope.row.bStatus === -1 ? '取消' : '补单' }}</el-button
                            >
                            <el-button @click="handleDeleteBtn(scope.row)" type="text" size="small">删除</el-button> -->
                            <el-button v-if="statusName(scope.row) == '等待支付'"
                                @click="changeData(scope.row), (dialogVisible = true)" type="text" size="small">
                                变更状态
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px" v-if="page.total != 0">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                    </el-pagination>
                </div>
            </div>
        </el-row>
        <div>
            <!-- 更改状态弹窗 -->
            <el-dialog v-model="dialogVisible" top="30vh" title="变更状态" width="30%" :before-close="handleClose">
                <div style="text-align: center">
                    <el-radio v-for="item in statusList" :key="item.id" v-model="statusForm" :label="item.id">{{
                    item.name
                    }}</el-radio>
                    <!-- <el-radio v-model="radio2" label="2">Option 2</el-radio> -->
                </div>
                <!-- <template #footer> -->
                <!-- </template> -->
                <div style="text-align: center; margin-top: 30px">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="handleConfirmBtn">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import { statusArray } from '@/common/js/order-manage-data/status-list.js';
import jsonTool from '@/utils/jsonTool.js';
import { get as getStorage, set as setStrorage } from '@/utils/storage.js';
import {
    getPackageOrderList,
    packageOrderChangeStatus,
    PackageOrderEdit_pay_status
} from '@/common/api/order-manage/packageOrder.js';
export default {
    name: 'packageOrder',
    data() {
        return {
            searchForm: {
                searchVal: '',
                status: ''
            },
            statusForm: '',
            // 状态列表
            statusArray,
            //表格数据
            packageOrderDataList: [],
            // 权限操作集合
            permissionsAction: {},
            page: {
                page: getStorage('currentPage', true) || 1, // 当前页
                pageSize: 10, // 页大小
                total: 0 // 总数量
            },
            // 更改状态弹窗
            dialogVisible: false,
            statusList: [
                {
                    name: '交易成功',
                    id: '1'
                },
                {
                    name: '交易失败',
                    id: '2'
                }
            ],
            id: ''
        };
    },
    mounted() {
        this.queryPackageOrderData();
    },
    methods: {
        changeData(e) {
            // console.log(e.bStatus);
            this.statusForm = e.bStatus;
            this.id = e.bId;
        },
        // 处理确认按钮
        handleConfirmBtn() {
            let params = {
                id: this.id,
                status: this.statusForm
            };
            // console.log(params);
            PackageOrderEdit_pay_status(params)
                .then(res => {
                    let { code, msg } = res;
                    if (code === 200) {
                        this.$message({
                            type: 'success',
                            message: `${msg}`,
                            center: true
                        });
                        this.dialogVisible = false;
                        this.queryPackageOrderData();
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 状态名
        statusName(row) {
            return this.statusArray.find(val => val.id === row.bStatus)?.name;
        },
        // 查询表格数据
        queryPackageOrderData() {
            let params = {
                page: this.page.page,
                pageSize: this.page.pageSize,
                search: this.searchForm.searchVal,
                b_status: this.searchForm.status
            };
            getPackageOrderList(params)
                .then(res => {
                    let { code, result, Power } = res;
                    if (code == 200) {
                        this.packageOrderDataList = jsonTool(result);
                        let { Delete: del, Enable: enable } = Power;
                        this.permissionsAction = {
                            del,
                            enable
                        };
                        this.page.total = res.count;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理查询按钮
        handleSearch() {
            this.queryPackageOrderData();
        },
        // 处理查看按钮
        handleToViewBtn(row) {
            this.$emit('packageOrderInfo', row);
        },
        // 处理补单/取消按钮
        handleChangeStatus(row) {
            let actionName = row.bStatus === -1 ? '取消' : '补单';
            let params = {
                b_id: row.bId,
                get_b_status: row.bStatus
            };
            this.$confirm(`是否进行${actionName}操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    packageOrderChangeStatus(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!',
                                    center: true
                                });
                                this.queryPackageOrderData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理删除按钮
        // handleDeleteBtn(row){
        //     console.log('处理删除按钮', row);
        //     this.$confirm('是否进行删除操作?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning',
        //         center: true
        //     }).then(() => {
        //         this.$message({
        //             type: 'success',
        //             message: '删除成功!',
        //             center: true
        //         });
        //     }).catch(() => {
        //         this.$message({
        //             type: 'success',
        //             message: '已取消操作!',
        //             center: true
        //         })
        //     });
        // },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryPackageOrderData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            // 分页当前页存储到sessionStorage
            setStrorage('currentPage', this.page.page, true);
            this.queryPackageOrderData();
        }
    }
};
</script>

<style lang="scss" scoped>
.change-status-page {
    .el-radio-button {
        margin: 0 20px 20px 0;
        border: 1px solid #dcdfe6;
    }

    .footer-wrap {
        text-align: center;
    }
}
</style>