export const statusArray = [
    {
        id: '',
        name: '全部'
    },
    {
        id: 1,
        name: '交易成功'
    },
    {
        id: 2,
        name: '交易失败'
    },
    {
        id: -1,
        name: '等待支付'
    }
];

export const payMethodLsit = [
    {
        id: 1,
        name: '支付宝'
    },
    {
        id: 2,
        name: '微信'
    },
    {
        id: 3,
        name: '银行卡'
    },
    {
        id: 4,
        name: '现金'
    }
]
