import $http from '../../../utils/http.js';

/**
 * 套餐订单列表
 * @param data
 */
export function getPackageOrderList(data) {
    return $http.get('/PackageOrder/index', data, 'loadingDiv');
}

/**
 * 套餐订单列表补单/取消操作
 * @param data
 */
export function packageOrderChangeStatus(data) {
    return $http.post('/PackageOrder/b_status', data, 'loadingDiv');
}

/**
 * 套餐订单列表删除操作
 * @param data
 */
// export function packageOrderDeleteAction(data) {
//     return $http.post('/PackageOrder/delete', data, 'loadingDiv');
// }

/**
 * 套餐订单列表详情信息
 * @param data
 */
export function getPackageOrderDetailsInfo(data) {
    return $http.post('/PackageOrder/p_o_details', data, 'loadingDiv');
}


/**
 * 套餐订单列表详情信息
 * @param data
 */
export function PackageOrderEdit_pay_status(data) {
    return $http.post('/PackageOrder/edit_pay_status', data, 'loadingDiv');
}



